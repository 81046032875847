// api.js
import axios from '@/store/interceptor';

import apiUrl from '../../config/api.config'

export async function getRequests() {
    try {
        const response = await axios.get(`${apiUrl}/requests`);
        return response.data;
    } catch (error) {
        console.error("Error fetching requests:", error);
        throw error;
    }
}

export async function getRequestTimelineData(requestId) {
    try {
        const response = await axios.get(`${apiUrl}/responses/${requestId}/timeline`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching timeline for request ${requestId}:`, error);
        throw error;
    }
}

export async function getUserById(personId) {
    try {
        const response = await axios.get(`${apiUrl}/users/${personId}/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
    }
}

export async function getUserPhoneNumber(idnp) {
    try {
        const response = await axios.get(`${apiUrl}/users/contacts/${idnp}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching user phone number:", error);
        throw error;
    }
}

export async function getRequestDetails(requestId) {
    try {
        const response = await axios.get(`${apiUrl}/requests/${requestId}/requests-details`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching details for request ${requestId}:`, error);
        throw error;
    }
}

export async function getHistoryRequests() {
    try {
        const response = await axios.get(`${apiUrl}/requests/requests-history`);
        return response.data;
    } catch (error) {
        console.error("Error fetching requests:", error);
        throw error;
    }
}

export async function getHistoryRequestsByUser(personId) {
    try {
        const response = await axios.get(`${apiUrl}/requests/${personId}/user-requests-history`);
        return response.data;
    } catch (error) {
        console.error("Error fetching getEquipmentModels:", error);
        throw error;
    }
}

export async function saveResponseData(postData) {
    try {
        const response = await axios.post(`${apiUrl}/responses/`, postData, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error saving request:", error);
        throw error;
    }
}

export async function updateRequests(requestData) {
    try {
        const response = await axios.patch(`${apiUrl}/requests/${requestData.id}`,
            {
                current_etap: requestData.current_etap,
                service_price: requestData.service_price,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error updating Requests:", error);
        throw error;
    }
}

